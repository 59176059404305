@content-width: 456px;

.donate_note_wrapper {
  display: flex;
  flex-basis: 100%;
  justify-content: flex-start;
  max-width: @content-width;
  margin: 0 auto;
  min-height: 50px;
  flex-wrap: wrap;

  div.note_wrap {
    margin-top: 12px;
  }
}

.client_note_wrapper {
  flex-basis: 100%;
  max-width: @content-width;
  min-height: 50px;

  .note-field-error {
    margin-left: 16px;
  }

  & > label {
    padding-left: 16px;
  }
}

.client_note_row {
  max-width: 456px;
  min-height: 44px;
  margin-bottom: 24px;
}

.note-field-error {
  margin-bottom: 8px;
  margin-top: 12px !important;
  margin-left: 40px;
}

.client_note_divider {
  flex-basis: 100%;
  max-width: 350px;
}

.donate_note_wrapper,
.client_note_wrapper {
  word-break: break-word;
  color: @ppui-v2-color-grey-600;

  .note_wrap {
    height: 100%;
    width: 100%;
    margin-bottom: 12px;

    textarea {
      height: 100%;
      width: 100%;
      max-width: 380px;
      text-align: left;
      resize: none;
      border: none;
      outline: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1.125rem;

    & > button {
      background-color: transparent !important;
      & > span {
        font-size: 1.125rem !important;
        font-weight: 900;
      }
    }
  }

  .body-text-label {
    word-break: break-word;
    color: @ppui-v2-color-grey-600 !important;
  }
}
