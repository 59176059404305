.currency-conversion-option {
  padding: 24px 48px 25px 40px;
  position: relative;
  max-width: 390px;
  margin: 0 auto;
  cursor: pointer;
  border: solid 1px #d0d6da;
  border-radius: 6px;

  &.option-selected {
    border: 1px solid #00cf92;
  }

  .option-icon-container {
    // width: 70px;
    // height: 100%;
    // display: block;
    // margin: 0 auto;
  }

  .option-icon {
    font-size: 80px;
    color: #00c99c;
  }

  .options-container {
    .option-title {
      color: #0270ba;
    }

    .option-details {
      // .rate {
      // 	margin-bottom: 0;
      // }
    }
  }

  .highlighted-option {
    position: absolute;
    left: -32px;
    top: 30%;
    height: -1px;
    background: white;

    .highlight-icon {
      font-size: 64px;
      color: #00c99c;
    }
  }
}
