//Money Input

.amount_holder {
  :invalid {
    box-shadow: none;
  }

  :-moz-submit-invalid {
    box-shadow: none;
  }

  :-moz-ui-invalid {
    box-shadow: none;
  }
  margin: 8px auto 24px;

  .money_input {
    color: black;
    margin: 0 auto;
    position: relative;
    text-align: center;
    font-size: 55px;
    height: 48px;
    z-index: 1;
    margin-bottom: 0.25rem;

    .amount_currency {
      display: inline-block;
      font-size: 24px;
      vertical-align: top;
      margin-bottom: 0.75rem;
      margin-right: 0.25rem;
      // PayPalSansSmall-Regular
      // PayPalSansBig-Light
      font-family: 'PayPalSansBig-Light', Helvetica Neue, Arial, sans-serif;

      .amount_currency_symbol {
        color: @ppui-color-grey-600;
      }
    }

    .amount_number {
      display: inline-block;
      height: 48px;
      color: @ppui-color-grey-600;
      font-family: 'PayPalSansBig-Regular', Helvetica Neue, Arial, sans-serif;
      font-size: 2.75rem;
      line-height: 3rem;
      -webkit-font-smoothing: antialiased;
      // margin-top: -0.20em;
      outline: none;
      width: 110px;
      font-weight: 400;
      border: 0;
      text-align: left;
      padding: 0;
      vertical-align: top;
      background-color: transparent;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &:focus[value=''] {
        text-align: right;
      }
    }
  }

  p {
    // font-family: "HelveticaNeue-Thin", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin: 0 auto;
    // font-family: @dn-font;
  }
}
