@import '../../node_modules/pp-tokens/build/web/theme/paypal/tokens.less';
@import 'color-tokens.less';
@import 'common/_utilities';
@import 'common/_shakeAnimation';
@import 'common/_common';
@import 'common/ppvx_guest';
@import 'common/_cvvComponent';
@import 'common/_crossBorder';
@import 'common/_tooltip';
@import 'common/_error';
@import 'common/_presetAmount';
@import 'common/_header';
@import 'common/_footer';
@import 'common/_paypalBranding';

@import 'common/ppvx_userDetailsBanner';
@import 'common/ppvx_landing';
@import 'common/_amount';
@import 'common/_recurring';
@import 'common/_note';
@import 'common/_ppvx-spinner';
@import 'common/_currency-conversion-options';
@import 'common/_confirmation';
@import 'common/_iframe';
@import 'common/_button';
@import 'common/_gnBanner.less';
@import 'common/_giftaid.less';
@import 'donate/campaign.less';

/* ratelimiter 3.0 */
@import '../../node_modules/adsinterceptor/src/less/captcha';

.consumer-fonts {
  @import '~iconfont-consumer-paypal/dist/icons.less';
}
