.donate_guest_form,
.gn_donate_guest_form {
  .donate_guest_form_wrapper {
    width: 100%;
    min-height: 100px;

    #billing_state,
    #countrySelection {
      font-size: 1.125rem;
      font-weight: normal;
      border-color: #909191;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    .create_account_tile {
      margin-top: 24px;
      display: flex;
      flex-direction: row;
      background-color: #f5fbfe;
      border: 0.0625rem solid #bfdbee;
      box-shadow: unset !important;

      @media only screen and (max-width: 414px) {
        padding: 12px;
      }

      .icon {
        width: 84px;
        margin-right: 20px;
        padding-bottom: 50px;
      }
    }

    .legalWrapper {
      margin-top: 20px;
      position: relative;
    }

    .password_fields {
      margin-top: 2.25rem;
      margin-bottom: 1.25rem;
      text-align: initial;

      &.nna {
        margin-bottom: 12px;
        padding: 24px;
        background: @ppui-color-blue-200;
        box-shadow: 0px 2px 4px 0px #00000029;
        border-radius: 12px;

        .nnaTitle {
          display: flex;
          align-items: center;
        }

        label[for='createAccountSwitch'] {
          margin-bottom: 16px;
        }
      }
    }

    .offerEnds {
      text-align: center;

      .termsButton {
        font-size: 14px;
        padding: 0px;
        color: #0070ba;
      }
    }
  }
}

.guest-form-section-heading {
  margin-top: 24px;
}

#auTaxModal {
  max-width: 612px;
  padding-bottom: 56px;
  text-align: center;

  img {
    width: 82px;
  }

  button {
    margin-top: 12px;

    &:first-of-type {
      margin-top: 24px;
    }
  }
  .heading-text {
    margin-top: 14px;
  }
  .body-text {
    margin-top: 24px;
    text-align: left;
  }
}

#dropdown_errorText {
  text-align: left;
}

.offer_term_list {
  margin-left: 48px;
}

#au_crs_info_banner,
#ca_aml_warning_banner {
  margin: 0 12% 12px;
}
#cip_warning_banner {
  margin: 0 0 24px;

  a {
    text-decoration: underline;
  }
}
form.donate_guest_form {
  div.amount_holder {
    div.money_input {
      input.amount_number,
      .amount_currency_symbol {
        color: @ppui-color-grey-700;
      }
    }
  }
  #recurringCheckboxContainer {
    margin-bottom: 0px !important;
  }
}
.guest-form-sections {
  margin-top: 36px;
}
.guest-form-sections,
.pay-debit-credit-header {
  margin-bottom: 16px;
  font-size: 0.875rem !important;
  line-height: 0.875rem !important;
}

.pay-debit-credit-header {
  margin-top: 26px;
  margin-left: 0;
  margin-right: auto;
  width: 100%;
  max-width: 400px;
}

.signup_switch {
  margin-bottom: 2rem;
}

.account-pre-value-prop2 {
  background-color: @ppui-v2-color-neutral-100;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 36px;
}
.account-pre-value-prop1 {
  background-color: unset !important;
  padding: unset !important;
  border-radius: unset !important;
}

.value-prop-container {
  display: flex;
  justify-content: space-evenly;
  // margin: 16px;
  margin: 36px 16px 36px 16px;
}

.value-prop-container-TRMT_2 {
  margin: 24px 16px 8px 16px !important;
}

.value-prop-img-cotainer {
  width: 64px;
  height: 64px;
  background-color: @ppui-v2-color-neutral-100;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.value-prop-img-cotainer-TRMT_2 {
  background-color: @ppui-v2-color-white !important;
}

.value-prop-img {
  width: 36px;
  height: 36px;
}
.value-prop-title {
  display: flex;
  text-align: center;
  font-family: PayPal Sans Big;
  margin-top: 8px;
  color: @ppui-v2-color-grey-600;
}
.value-prop-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.guest-form {
  &__submit-button {
    margin-top: 1.5rem;
  }
}

#country-error-msg {
  div[role] {
    display: flex;
    align-items: center;
    color: #515354 !important;
    font-weight: 500;
    line-height: 1.25rem;
    padding-top: 0;
    padding-bottom: 0;

    span {
      font-size: 1.5rem;
      margin-right: 0.25rem;
      color: #d9360b;
      width: auto;
      height: auto;
    }
  }
}
