.paypal_branding {
  display: flex;
  justify-content: center;
  padding: 24px 25px 48px 25px;

  p {
    font-family: 'pp-sans-big-regular';
    font-size: 1rem;
    margin: 0;

    img {
      padding-left: 5px;
      vertical-align: middle;
    }
  }
}
