.no-margins {
  margin: 0;
}

.mt-16 {
  margin-top: 16px;
}

.mt-12 {
  margin-top: 12px;
}

.mb-16 {
  margin-bottom: 16px;
}

.ml-4 {
  margin-left: 4px;
}

.text-center {
  text-align: center !important;
}

.confirmation_text {
  color: @ppui-v2-color-black;
}

.confirmation-description {
  font-size: 1.1rem !important;
  color: @ppui-v2-color-black;
}
.transaction-id-text {
  color: @ppui-v2-color-black;
}
.return-message {
  margin-bottom: 16px;
}

.icon-ultralarge {
  font-size: 128px;

  &.warning {
    color: #ffbd5d;
  }

  &.success {
    color: #299976;
  }
}

.paypal-color {
  color: @ppui-color-blue-600 !important;
}

.inline-block {
  display: inline-block;
}

.pp-logo-container {
  margin-bottom: 32px;
}

.OTP-heading {
  padding: 0px 10%;
  margin-bottom: 24px;
  @media @mobile {
    padding: 0px;
  }
}

#stepUp {
  width: 500px;
  height: 640px;
}
