body {
  background-color: @ppui-color-white !important; //responsive.less#L11
  background-image: none !important;
  position: relative;
  color: @ppui-color-grey-700;

  &.ccpaCookieBanner-acceptedAll {
    height: 100% !important;
    margin: 0 !important;
  }
}

.donate {
  a:not([class*='links_base-text']) {
    font-family: PayPalOpen-Bold, 'Helvetica Neue', Arial, sans-serif !important;
    color: @sys-color-link-main !important;
    font-size: inherit !important;
    line-height: inherit !important;

    &:hover {
      color: @sys-color-link-main-hover !important;
    }
  }

  a.btn-line-height {
    line-height: 2.5rem !important;
  }
}

#mainWrapper {
  min-height: 100%;
  padding-bottom: 8rem;
  margin: auto;
  float: none;
  position: absolute;
  touch-action: pan-y;
  width: 100%;

  header.hide-header {
    display: none;
  }
}

body.no-pulltorefresh {
  overscroll-behavior: contain;
}

body.donate_incontext {
  background-color: #ffffff !important;

  #mainWrapper.landing-page {
    min-height: fit-content;
  }

  #mainWrapper.in-context-success-page {
    padding-bottom: 0px;

    div.paypal_branding {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
  }
}

div.landing-page {
  header.donate_global_nav,
  div.paypal_branding {
    display: none;
  }

  #donateWrapper {
    margin-top: 36px;

    @media @mobile {
      margin-top: 0px;
    }
  }
}

#donateWrapper {
  padding-top: 24px; //responsive.less#L14
  padding-bottom: 48px;

  @media @mobile {
    padding: 0px;
  }

  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
  width: 100%;
  max-width: 618px;

  .container {
    width: 100%;
    padding: 0px 80px;
    margin-top: 24px;

    @media @mobile {
      padding: 0px 16px;
    }
  }

  &.campaigns.is-landing {
    max-width: 1152px;
    padding: 0;

    .container {
      padding: 0px 24px;
    }

    footer {
      @media @mobile {
        height: 18rem;
      }
      @media @tablet {
        height: 15rem;
      }
    }
  }

  .landing-programs {
    margin-bottom: 33px;
  }

  .gn_donate_wrapper {
    padding-bottom: 56px;
    background-color: white;
    border-radius: 0.75rem;
    border: 1px solid @ppui-v2-color-neutral-300; //responsive.less#L16
    position: relative;
    overflow: inherit;

    .banner-image {
      border-top-right-radius: 0.75rem;
      border-top-left-radius: 0.75rem;
    }

    @media @mobile {
      border: 0px;
    }

    .user-details-banner {
      margin-bottom: 0;
    }

    .success_content {
      margin: 0 80px;
      word-break: break-word;

      @media (max-width: 420px) {
        padding: 0 12px;
      }

      @media @mobile {
        margin: 0 16px;
      }
    }

    .success_subheader {
      margin-bottom: 24px;
      font-size: 1.1rem;
      color: @ppui-v2-color-black;
    }

    .done-link {
      margin-top: 24px;
    }

    .client_note_wrapper > label {
      padding-left: 0;
    }

    fieldset {
      margin-left: 0;
    }

    .text-padding-top {
      padding-top: 24px;
    }

    .donate_banner_wrapper {
      .banner_img_holder {
        img {
          margin-top: 37px;
          margin-bottom: 24px;
        }
      }
    }

    .landing-programs {
      button {
        border: 1px solid #c6c6c6;
      }
    }
  }

  .donate_wrapper {
    padding: 48px 0px;
    background-color: white;
    border-radius: 0.75rem;
    border: 1px solid @ppui-v2-color-neutral-300; //responsive.less#L16
    position: relative;

    @media @mobile {
      border: 0px;
    }

    .success_content {
      div[class*='module_post_donation_widget_container'] {
        margin: 2rem 4rem 4rem;
      }
    }
  }

  .context_gn_wrapper {
    border: none;
  }

  .donate_wrapper .context_gn_wrapper {
    .back-button {
      color: @ppui-v2-color-grey-600;
    }
  }

  .mobile_view {
    border-radius: 0px;
    border-width: 0px 0px 0px 0px; //Removed  on bottom since another divider replaced this one.
  }

  .mobile_view_without_cancel_message,
  .mobile_view_without_cancel_message_review,
  .mobile_view_without_return_message {
    border-bottom: 8px solid @ppui-v2-color-neutral-100;
    margin-bottom: 14px;
  }

  .mobile_view_without_cancel_message_review {
    margin-bottom: 4px;
  }

  .mobile_view_without_return_message {
    margin-bottom: 4px;
  }

  .donate_wrapper.user-banner {
    padding-top: 0px;
  }

  .close-button {
    z-index: 1;
    position: absolute;
    right: 6px;
    top: 6px;
  }

  .back-button {
    position: absolute;
    top: 6px;
    left: 6px;
    z-index: 1;
  }

  .user-banner .close-button {
    top: 14px;
  }

  .user-banner .back-button {
    position: relative;
    top: 0;
    left: 0;
  }

  .amount-wrapper {
    margin-top: 24px;
    font-family: PayPalOpen-Regular, 'Helvetica Neue', Arial, sans-serif;
  }

  .frequency-options {
    margin-bottom: 35px;
  }

  .preset-amount-wrapper {
    padding: 12px 0px 35px 0px;
  }

  .note-field-error {
    margin-top: 4px;
    line-height: 20px;

    .critical-icon {
      color: #d20000;
      margin-right: 0.75rem;
    }

    .error-text {
      color: #687173;
      font-size: 14px;
      vertical-align: top;
    }
  }
}

.remove_mobile_margin {
  @media only screen and (max-width: 414px) {
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
}

/**
 * Style for all the legal texts
 * 1. Color is muted
 * 2. Size is smaller
 */
#donateWrapper .legal-text {
  margin-top: 16px;

  label {
    line-height: 18px;
    font-size: 13px;
    color: #6c7378;
  }

  a {
    color: @ppui-v2-color-blue-400 !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
}

// The main Donate buttons
// 50px lesser than form elements
.btn-wide {
  width: 100%;
  white-space: normal !important;
}

.next-button {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

// The line between the 2 Donate buttons
.or-line-container {
  margin: 8px 0;
}

.guest-terms {
  margin-top: 16px;
  padding-top: 16px;

  &.small {
    border: none;
    padding-top: 0;
  }
}

.tooltip-accessible {
  display: inline;
  font-size: 1rem;
  cursor: pointer;
}

.already-enabled {
  > span {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      line-height: 0.81rem; // Specific workaorund for IE, because it has problems with the calculating @ppvx-tooltip-space
    }
  }
}

.share-address-checkbox {
  &__tooltip {
    margin-left: 0.5rem;
    display: inline-flex;
    position: absolute;

    button {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
}

// Cancel and Return button

.pp_cancel_btn_wrapper {
  padding-top: 24px;
  margin-bottom: 48px;
}

.is_mobile {
  margin-bottom: 4px;
  border-top: 8px solid @ppui-v2-color-neutral-100;
}

.is_landing {
  margin-bottom: 38px;
}

.accordion-no-border {
  .accordion-body {
    padding: 0 1rem 1rem;
  }
}

.voucher-disclaimer {
  a {
    font-size: 0.8rem;
  }
}

.button-link {
  display: flex;
  align-items: center;
  padding: 0px;
  color: #0070ba;
  font-size: 1rem;

  span {
    margin-right: 0.375rem;
    font-size: 1.875rem;
  }
}

.bg-banner {
  margin-bottom: 20px;
  position: relative;
  display: block;

  .bg-banner-image {
    width: 100%;

    @media @mobile {
      border-radius: 0;
    }
  }
}

#au_crs_signup_banner {
  margin-bottom: 24px;
}

#donateWrapper div.fullPageError {
  border: none;
  padding-top: 30vh;

  img {
    display: block;
    margin: 0 auto;
  }

  .title {
    text-align: center;
    margin-top: 40px;
  }

  .body {
    margin-top: 24px;
  }

  .cta {
    display: block;
    margin: 36px auto 0;
    text-align: center;
  }

  @media @mobile {
    padding: 60px 16px 0;

    .title {
      margin-top: 20px;
    }
  }
}

main.full-page-error {
  .footer_in_context {
    min-height: 10rem;
    margin-top: -10rem;
    padding: 0;
    position: absolute;
  }
}

div.full-page-error {
  .paypal_branding {
    display: none;
  }
}

.sticky {
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06), 0px 2px 14px rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 100%;
  padding: 18px 0 18px 44px;
}

.amount.no-error {
  margin-bottom: 35px;
}

.ppcc_tooltip {
  display: inline-block;
  width: 0;
  height: 44px;
  overflow: visible;
  vertical-align: top;
  margin-top: -10px;
  margin-bottom: -17px;
  transform: translateX(-6px);
}

.ppcc_tooltip_within_header {
  display: inline-block;
  width: 0;
  height: 44px;
  overflow: visible;
  vertical-align: top;
  margin-top: -3px;
  margin-bottom: -17px;
  transform: translateX(-6px);
}
