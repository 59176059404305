.spinneroverlay,
.three-ds-spinner {
  display: none;

  &.donatespinner {
    display: block;
  }
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.97);

  .loadingcontent {
    margin-top: 20%;
    width: 100%;
    p {
      margin-top: 10px;
      font-size: 18px;
      font-weight: 400;

      &#spinnerText {
        font-size: 12px;
        color: @ppui-color-grey-400;
      }
    }
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spinner {
    height: 60px;
    width: 60px;
    margin: 94px auto 0;
  }
  #waitText {
    margin-top: 24px;
  }
}

.three-ds-spinner {
  z-index: 1049; // 1 less than the threeDS Iframe z-index of 1050
}
