.donate-giftaid {
  display: flex;
  justify-content: center;
  padding: 0px;

  .giftaid {
    padding: 16px 16px 27px 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 100%;
    margin: 10px auto 15px auto;
    max-width: 456px;

    .image-wrapper {
      display: block;
      margin: 0px auto 8px auto;
    }
    .giftaid-note {
      margin-bottom: 20px;
      text-align: left;
    }
    .already-enabled {
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: center;
      .success-icon {
        margin-left: -5px;
        padding-right: 5px;
        height: 46px;
      }
    }
    .learn-more-button {
      padding: 0px 12px;
    }
    .show-help {
      margin-top: 1.5rem;
      text-align: left;
      .help-message {
        margin-bottom: 20px;
        text-align: left;
      }
    }
    .show-adress {
      margin-top: 15px;
      margin-left: 42px;
      color: @ppui-color-grey-700;
      border-bottom: 1px solid #ccc;
      padding-bottom: 13px;
      text-align: left;

      .list-unstyle {
        margin: 0px;
        list-style: none;
        li {
          margin-bottom: 2px;
        }
      }
    }
    .update-giftaid-declaration {
      padding-top: 15px;
    }
  }
}
