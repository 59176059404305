.donate-main-content {
  padding: 0px 80px;
  margin-top: 25px;

  @media @mobile {
    padding: 0px 16px;
  }
  .title-secondary {
    color: @ppui-color-grey-600;
  }

  .purpose {
    padding: 0 36px;
    text-align: center;

    @media only screen and (max-width: 414px) {
      padding: 0 12px;
    }
  }

  .landing-purpose {
    margin-top: 8px;
    text-align: center;
  }
}

.campaigns {
  .donate-main-content {
    padding: 0px 24px;
  }
  .org-content {
    padding: 24px 24px 0;
  }
}
.anonymous-checkbox,
.checkbox-bottom-margin {
  margin-bottom: 35px !important;
  padding-left: 10px;
}
.anonymous-checkbox label {
  display: flex;
}
.anonymous-checkbox-tooltip {
  border: none !important;
  background-color: transparent !important;
  button {
    height: 1.125rem;
  }
  &::after {
    box-shadow: none !important;
    border: none !important;
  }
}
.payment-disclaimer {
  margin-bottom: 24px;
}

#recurringCheckboxContainer {
  margin-bottom: 35px !important;
}

.recurring-checkbox-left-align,
.cover-fee-checkbox-wrapper,
.anonymous-checkbox-wrapper {
  margin: 0.75rem auto;
  max-width: 456px;
  padding-left: 0.25rem;

  .padding-0 {
    padding: unset;
  }

  @media (max-width: 414px) {
    padding: 0 5px;
  }

  @media (max-width: 375px) {
    padding: 0 12px;
  }
}

.cover-fee-checkbox-wrapper {
  margin-bottom: 24px;
}

.anonymous-checkbox-wrapper {
  margin-bottom: 24px;
  padding: 0;

  @media (max-width: 375px) {
    padding: 0 12px;
  }

  span[class*='icon_label'] {
    margin-left: 0;
  }
}

.client-error-alert {
  width: 80%;
  margin: 12px auto;
}

.amount-validation-error-container {
  max-width: 350px;
  margin: 4px auto;
}

.amount-validation-error-container-preset {
  max-width: 440px;
  margin: 0 auto;
  padding-top: 8px;
}

.donate-main-content-with-no-margin {
  margin-top: 0px !important;
}
