.campaign-landing-container {
  .campaign-landing-banner {
    border-bottom-left-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
    @media @mobile {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .content-container {
    position: relative;
    width: 100%;
    padding: 0px 35px 35px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-between;

    @media @mobile {
      margin-top: 17px;
      padding: 0px 16px;
    }
  }

  .preview-header {
    display: none;

    &.sticky {
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.06),
        0px 2px 14px rgba(0, 0, 0, 0.1);
      z-index: 1;
      padding: 9px 35px;

      .wrapper-header {
        display: flex;
        align-items: center;
        flex: 1;
        max-width: 1152px;
        padding: 0 35px;

        .wrapper-badge {
          order: 2;
          margin-right: 2rem;
          margin-bottom: 0;
        }

        .wrapper-title {
          order: 1;
          flex: 1;
        }

        .wrapper-progress-meter {
          order: 3;
        }
      }
    }
  }

  .campaignDetails {
    flex: 1;
    margin-right: 24px;
    overflow: hidden;

    @media @mobile {
      margin: 0;
    }

    .campaign-title {
      margin: 24px 0px;
      word-break: break-word;

      @media @mobile {
        margin: 0;
      }

      .wrapper-avatar-stack {
        margin: 24px 0px;

        @media @mobile {
          display: none;
        }
      }

      .wrapper-badge {
        margin-bottom: 24px;
      }

      .wrapper-progress-meter {
        .goal-progress-meter {
          display: flex;
          flex-direction: column;
          padding: 0;

          label {
            margin-top: 3px;
          }
        }
      }

      .wrapper-donation-name {
        display: none;

        @media @mobile {
          display: block;
          margin-top: 20px;
          margin-bottom: 8px;
        }
      }
    }

    .campaign-description {
      margin-bottom: 88px;
      position: relative;
      overflow: hidden;
    }

    .campaign-updates {
      margin-bottom: 140px;

      .update-container {
        margin-bottom: 44px;

        .organizer-updates {
          position: relative;
        }
      }
    }

    .campaign-read-more {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      margin: 0;
      padding-top: 10rem;
      background-image: linear-gradient(
        to bottom,
        transparent,
        @ppui-color-white
      );

      button {
        padding: 0;
        width: 100%;
        background-color: #fff;
        font-size: 1rem;

        &:active,
        &:focus,
        &:hover,
        &:focus::after {
          border: none;
          outline: none;
          box-shadow: none;
          text-decoration: none;
        }
      }
    }

    div[class*='rich_text_editor'] {
      margin-top: 20px;
    }
  }

  .paymentCard {
    background: @ppui-v2-color-white;
    width: 396px;
    position: sticky;
    z-index: 3;
    top: 104px;
    margin-top: -90px;
    margin-right: 10px;
    border-radius: 0.75rem;
  }
}
.campaign-background-image {
  background-repeat: no-repeat;
  background-size: cover;
  height: 20rem;
  width: 100%;
  flex: 0 0 100%;
}

.campaign-landing-container .mobileDonate {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  padding: 16px 16px 52px;
  background-color: @ppui-color-white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.04), 0px 2px 18px rgba(0, 0, 0, 0.1);

  button {
    width: 100%;
  }
}

#flyout {
  background-color: #f7f5f0;
  display: flex;
  max-width: 290px;
  min-width: 290px;
  position: fixed;
  border-radius: 18px;
  padding: 12px;
  left: 772px;
  right: 0;
  margin: 0px auto;
  z-index: 2;
  bottom: 16px;

  &.slideIn {
    -webkit-animation: slideIn 0.5s forwards;
    animation: slideIn 0.5s forwards;
  }

  &.slideOut {
    -webkit-animation: slideOut 0.5s forwards;
    animation: slideOut 0.5s forwards;
  }

  .avatar {
    flex-shrink: 0;
    margin-right: 12px;
  }
}

.donor-wall-card {
  display: flex;
  column-gap: 1rem;
  background-color: #f7f5f0 !important;
  border: none !important;
  padding: 1rem !important;

  &:hover {
    box-shadow: none !important;
  }

  .icon {
    display: flex;
    flex-direction: column;
    margin: auto 0.25rem auto 0.3125rem;
    color: #515354;
  }

  .content {
    display: flex;
    flex-direction: column;
    color: #0c0c0d;

    .description {
      margin-top: 0.5rem;
    }
  }
}

.social-share-wrapper {
  margin-top: 88px;

  .campaign-landing {
    text-align: left !important;
  }

  @media @mobile {
    margin-top: 48px;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: translateX(100vw);
    transform: translateX(100vw);
    visibility: visible;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes slideIn {
  0% {
    -webkit-transform: translateX(100vw);
    transform: translateX(100vw);
    visibility: visible;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@-webkit-keyframes slideOut {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    visibility: hidden;
    -webkit-transform: translateX(100vw);
    transform: translateX(100vw);
  }
}

@keyframes slideOut {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    visibility: hidden;
    -webkit-transform: translateX(10vw);
    transform: translateX(100vw);
  }
}

.campaign-subheader-title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  word-break: break-word;
}

.container.campaigns {
  padding: 0;
  max-width: 416px;

  .preset-amount-wrapper {
    margin: 24px 0;
  }
}

.organizer-updates-list {
  margin-bottom: 4.5rem;
}
.organizer-update-preview {
  margin-bottom: 1.5rem;
  cursor: pointer;
  h3 {
    display: flex !important;
    flex-wrap: wrap;
    column-gap: 10px;
    div {
      padding: 0;
    }
  }
}
.fetch-update-error-title,
.fetch-update-error-subtitle {
  padding-bottom: 24px;
}

.organizer-like-error {
  margin-bottom: 24px;
}

.login-popup {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .login-popup-icon {
    align-self: center;
    width: 124px;
    height: 124px;
  }

  .login-popup-title {
    text-align: center;
  }

  .login-popup-buttongroup {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  @media @mobile {
    gap: 16px;
  }
}

div.organizer-updates {
  button[class*='_like_button_'] {
    margin: initial;
  }
}
button[class*='_like_button_'] {
  margin-bottom: 1.5rem;
}
