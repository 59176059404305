.recurring-info {
  .line2 {
    margin-top: 1.5rem;
  }
}
div.recurring-info-container {
  padding-left: 0;
  padding-right: 0;
  margin-top: 8px;
  margin-bottom: -12px;

  div.recurring-info {
    max-width: 456px;
    margin: 0 auto 24px;
  }
}

.recurring-signup-overpanel {
  .icon-container {
    img {
      width: 120px;
      margin-bottom: 31px;
    }
  }
  .modal-body,
  .btn-wide {
    max-width: 28rem !important;
  }

  .modal-body {
    margin: 0 auto;

    .content {
      display: flex;
      flex-direction: column;

      .content-row {
        display: flex;
        margin-top: 16px;

        .icon-column {
          display: flex;
          justify-content: left;
          flex: 0 0 30px;
        }
        .text-column {
          word-break: break-word;
          text-align: left;
        }
      }
    }

    .actions {
      margin-top: 32px;
    }
  }
}

#donation_fraud_alert {
  margin-bottom: 24px;
  margin-top: 24px;
}

.incontext-guest-recurring {
  max-height: 100vh !important;
  height: 100vh !important;
  height: calc(var(--vh, 1vh) * 100) !important;
  border-radius: 0px !important;
}

.recurring-checkbox-wrapper-unchecked {
  margin-bottom: 24px;
  padding-left: 10px;
}

.recurring-checkbox-wrapper-checked {
  margin-bottom: 16px;
  padding-left: 10px;
}

.recurring-checkbox-checked {
  margin-bottom: 24px;

  .recurring-dropdown {
    margin-top: 10px;
    margin-left: 2.375rem;
  }
}

.recurring-checkbox-wrapper {
  .animation-wrapper {
    width: 100%;
    position: absolute;
    height: 125px;
    margin-top: -125px;

    .animation-container {
      height: 250px;
      width: 250px;
      margin-left: -35px;
    }

    .ltr-container {
      transform: scale(1);
    }
    .rtl-container {
      transform: scale(-1);
    }
  }

  .block-wrapper {
    display: block;
  }
  .none-wrapper {
    display: none;
  }
}

.recurring-info {
  // margin: 10px 0px;
  margin-bottom: 24px;
}
