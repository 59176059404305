.user_details_banner,
.user_details_banner_no_border,
.gn_user_details_banner,
.gn_user_details_banner_no_border {
  display: flex;
  justify-content: flex-start;

  padding: 13px 14px;
  border-bottom: solid 4px @ppui-v2-color-neutral-100;

  padding-right: 3.5rem;
  word-break: break-word;

  .avatar-container {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 46px;
      height: auto;
      border-radius: 50%;
    }

    background: white;
    border-radius: 50%;
  }

  .details-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 100%;

    font-size: 15px;
    line-height: 1.33;
    color: #2c2e2f;
    padding-left: 14px;

    sup {
      color: #4a4a4a;
    }

    .hi-msg {
      display: inline;
    }

    .not-you-link {
      margin-left: 4px;
      display: inline-block;
    }
  }
}

.gn_user_details_banner,
.gn_user_details_banner_no_border,
.user_details_banner_no_border {
  margin-bottom: 0px;
}

.gn_user_details_banner_no_border,
.user_details_banner_no_border {
  border-bottom: 0px;
}

.user_details_banner {
  margin-bottom: 24px;
}
