div.cross-border {
  padding: 0;
  max-width: 456px;
  margin: 56px auto 0 auto;
  .alert-container {
    margin-bottom: 24px;
  }
  .alignment-padding {
    padding-left: 18px;
    padding-right: 18px;
  }
  .cb-payment-information {
    display: flex;
    margin-bottom: 24px;

    .payment-info {
      display: flex;
      .payment-logo {
        height: 44px;
      }
    }

    .pay-info {
      margin-left: 12px;
      .funding-info {
        color: @ppui-color-grey-600;
      }
    }
  }

  .total-donation,
  .fee-donation {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between !important;
    align-items: center !important;

    .pricing-fee {
      display: flex;
      align-items: center;
    }

    .tooltip-icon-button {
      color: @ppui-v2-color-blue-600;
    }
  }

  .change-conversion,
  .legal-text {
    margin-bottom: 24px;
  }
  .change-conversion {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .total-donation {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .conversion-message {
    margin-bottom: 24px;
  }

  .conversion-block {
    max-width: 500px;
    width: 100%;
    margin: 20px auto 10px;
    padding-top: 23px;
    padding-bottom: 20px;
    background-color: #f1f8fc;
    border-radius: 5px;
  }

  .conversion-options-link {
    margin-top: 6px;
  }
  .cross-border-cancel-return {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: -86px;
  }

  @media @mobile {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

div.alert-container {
  margin: 32px auto 8px;
  max-width: 456px;

  @media @mobile {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.currency-conversion-modal {
  .title,
  .subTitle {
    margin-bottom: 24px;
  }
  .option-paypal {
    margin-bottom: 16px;
  }
  .option-card {
    margin-bottom: 32px;
  }
  .option-card,
  .option-paypal {
    cursor: pointer;
    padding: 15px 18px 15px 16px;
    .conversion-tile {
      display: flex;
      align-items: center;
      .content {
        margin-left: 16px;
        margin-right: 18px;
        .title {
          margin-bottom: 16px;
        }
      }
    }
    .icon-check {
      color: @ppui-v2-color-blue-600;
      margin-left: 16px;
    }
    .hide-icon {
      visibility: hidden;
    }
  }
  .show-highlight {
    border: 2px solid @ppui-v2-color-blue-600 !important;
    box-sizing: border-box !important;
    border-radius: 12px !important;
  }
  .border-default {
    border: 1px solid @ppui-v2-color-grey-300 !important;
  }
  .btn-sheet-wide {
    width: 100%;
    white-space: normal !important;
  }
}

.pricing-helper-sheet {
  .helper-text-description {
    margin-top: 24px;
  }
  .button-link {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
  }
}
