.preset-amount-row-container {
  margin: 30px auto 10px;
  @media (max-width: 440px) {
    margin: 24px auto 10px;
  }

  .preset-amount-row {
    margin: 0 auto;
    text-align: center;

    @media (max-width: 540px) {
      max-width: 350px;
    }

    .amount-btn-group {
      button {
        margin-right: 10px;
        height: 56px !important;

        @media (max-width: 540px) {
          min-width: calc((100% - 20px) / 3);
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    .buttons-container {
      display: inline-block;
      @media (max-width: 736px) {
        display: flex;
        justify-content: center;
      }

      @media (max-width: 540px) {
        min-width: 100%;
        display: inline-block;
      }

      @media (max-width: 319px) {
        div:nth-child(1) {
          font-size: 14px !important;
        }
      }

      .vx_btn {
        margin-bottom: 0;
        min-width: 86px;

        @media (max-width: 340px) {
          min-width: 4rem;
        }
      }
    }

    .amount-container {
      display: inline-block;
      vertical-align: top;
      text-align: left;
      @media (max-width: 736px) {
        display: block;
        width: 100%;
        margin: 10px auto 0;
      }

      div {
        @media (max-width: 540px) {
          min-width: 100%;
        }
      }
    }
  }
}

.preset-amount-row-in-context {
  .preset-amount-row {
    .amount-btn-group {
      button {
        min-width: 6.85rem;
        div:nth-child(2) {
          font-size: 12px !important;
        }
      }
    }
    .amount-container {
      div {
        max-width: 350px !important;
      }
    }
  }
}

.otherAmount {
  margin-top: 16px;
  margin-bottom: 24px;
}
