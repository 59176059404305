header.donate_global_nav {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;

  #logoImage {
    background-repeat: no-repeat;
    background-position: top left;
    height: 28px;
    width: 100%;
    display: block;
  }

  .btn_logout {
    background-color: rgba(169, 169, 169, 0.54);
    color: black;
    font-weight: 500;
    text-decoration: none;

    &:hover {
      background-color: darken(rgba(169, 169, 169, 0.54), 10%);
    }
  }

  .header_container {
    padding-left: 1rem;
    padding-right: 1rem;
    max-width: 100%;
  }

  .header_row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
  }

  @media (min-width: 47rem) {
    .col-md-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  @media (min-width: 36rem) {
    .col-form--full,
    .col-sm-12 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-sm-12,
  .col-md-12,
  .col-12 {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
  }
}
