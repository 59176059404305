footer {
  margin-bottom: 0;
  float: none;
  z-index: 1;
  background-color: @ppui-v2-color-white;
  clear: both;
  color: @ppui-color-grey-700;
  text-shadow: 0 1px 1px #ffffff;
  width: 100%;
  padding: 0;
  position: absolute;
  height: 8rem;
  min-height: 8rem;
  top: 100%;
  margin-top: -8rem;
  border-top: 1px solid #fff;
  left: 0;

  .footer_container {
    max-width: 100%;
    padding: 0 36px;
    border-top: 1px solid @ppui-color-grey-300;

    a {
      text-decoration: none;
    }

    .footer_d_one {
      ul {
        margin: 24px auto 10px;
        padding: 0;
        li {
          float: left;
          display: inline;
        }
        a {
          color: @sys-color-link-alt !important;
          margin-right: 20px;
          font-weight: 300;
          line-height: 20px;
          font-size: 14px;
        }
      }
    }

    .lang_selector,
    .feedback {
      float: right;

      button,
      button:hover,
      button:active,
      button:focus,
      button:focus::after {
        border: none;
        color: @ppui-color-grey-700;
        box-shadow: none;
        background: none;
        padding: 0;
        margin-top: 24px;
        font-size: 14px;
        line-height: 20px;
      }

      nav {
        position: absolute;
        bottom: calc(100% - 5px);
        right: 10px;
      }

      nav li p {
        color: #737a7f;
      }

      button {
        span {
          padding-left: 10px;
        }
      }
    }

    .conbiner_fin_h {
      a.conbiner_fin_l {
        color: @ppui-color-grey-600;
        margin-right: 20px;
      }
    }

    .footer_d_two {
      margin-top: 12px;
      ul {
        display: flex;
        flex-direction: row;
        padding-left: 0;
        margin: 5px auto 0;
        word-wrap: normal;
        li {
          line-height: 1.5rem;
          display: inline-block;

          p {
            display: inline;
            font-size: 14px;
            line-height: 20px;
            color: @ppui-color-grey-600;
          }

          .all_rights {
            margin: 5px 16px 0 0;
            white-space: pre;
            word-break: normal;
            word-wrap: normal;
          }

          .d_two_link a {
            margin-right: 16px;
            color: @sys-color-link-alt !important;
          }
        }
        .footer_d_two_links {
          margin-bottom: 24px;
        }
      }
    }

    .conbiner_fin_c a {
      color: @sys-color-link-alt !important;
      margin-right: 20px;
      font-weight: 300;
      line-height: 20px;
      font-size: 14px;
    }

    .footer_one {
      text-align: left;

      .footer_link {
        padding-top: 15px;
        padding-bottom: 15px;

        a {
          color: @ppui-color-grey-600;
          font-size: 10px;
          font-weight: bold;
          text-decoration: none;
        }
      }

      .links {
        display: flex;
      }
    }

    @media (max-width: 735px) {
      div:first-child {
        flex-direction: column-reverse;
        div {
          max-width: 100%;
        }
      }
      .lang_selector {
        float: left;
      }

      .footer_d_two {
        ul {
          flex-direction: column;
          li {
            p.all_rights {
              border-right: 0;
            }
          }
          .footer_d_two_links {
            margin-top: 12px;
          }
        }
      }
    }

    .feedback {
      button,
      button:hover,
      button:active,
      button:focus,
      button:focus::after {
        margin-top: inherit;
      }
    }
  }
  .mobile_footer_container {
    border-top: 0px;
  }

  .conbiner_fin_c {
    color: #6c6c6c;
    font-family: 'PayPalSansBig-Regular', Helvetica Neue, Arial, sans-serif;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    margin-bottom: 10px;

    .conbiner_fin_l {
      color: black;
      font-weight: 600;
      margin: 0 5px;
    }
  }
}

.mobile_footer {
  border-top: 8px solid @ppui-v2-color-neutral-100;
}

.footer_in_context {
  margin-top: 18px;
  border-top: 8px solid @ppui-color-neutral-100;
  position: relative;
  min-height: 13rem;
  padding: 1.5rem 0;

  .footer_container {
    border: none;
    div:first-child {
      flex-direction: column-reverse;
      div {
        max-width: 100%;
      }
    }

    div {
      margin: auto;
      width: 100%;
      max-width: 100%;
    }

    .footer_d_one {
      ul li {
        float: none;
        display: inline-block;
        a {
          color: @sys-color-link-alt;
          line-height: 20px;
        }
        a:nth-child(1) {
          margin-right: 16px;
        }
      }
    }

    .lang_selector {
      float: none;
      button,
      button:hover,
      button:active,
      button:focus,
      button:focus::after {
        color: @ppui-color-grey-700;
        margin-top: 0;
      }
    }

    .footer_d_two {
      ul {
        flex-direction: column;
        li:first-child {
          p span {
            color: @ppui-color-grey-600;
          }
        }
        li p span a {
          line-height: 20px;
          color: @ppui-color-grey-600;
        }

        .all_rights {
          border-right: none;
        }
        .footer_d_two_links {
          margin-bottom: 0;
          margin-top: 12px;
        }
      }
    }

    .conbiner_fin_c {
      padding: 4rem 0 1rem 0;
    }
  }
}

body.donate_incontext {
  .footer_in_context {
    position: absolute;
    top: 100%;
  }
}
