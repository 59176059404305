.member {
  background-color: white;
  width: 100%;
  max-width: 557px;
  border-radius: 5px;
  margin: 0 auto 10px;

  #donateWrapper {
    border: 0; //responsive.less#L16
    border-radius: 0;
    margin-top: 0; //responsive.less#L14
    margin-bottom: 0;

    .container {
      width: 100%;
    }
  }

  #p2pMemberFrame {
    width: 100%;
    max-width: 496px;
    background-color: transparent;
    min-height: 500px;
    border: 0;
  }
}

#donateWrapper.member {
  div.container {
    padding: 0 60px;

    @media @mobile {
      padding: 0px 16px;
    }
  }
}
