.pp_next_btn_wrapper {
  margin-top: 15px;
  margin-bottom: 15px;

  .pp_next_btn {
    width: 100%;
    max-width: 361px;
    height: 44px;
    font-size: 15px;
    padding-top: 10px;
    font-weight: 600;
    color: white;
    background-color: @ppui-color-blue-600;
    border: 1px solid @ppui-color-blue-600;
    text-decoration: none;

    &:focus,
    &:hover {
      background-color: darken(@ppui-color-blue-600, 10%);
    }
  }
}

.pp_guest_btn {
  cursor: pointer;
}

.pp_guest_btn_wrapper {
  margin-top: 15px;
  margin-bottom: 15px;

  .pp_guest_btn {
    width: 100%;
    max-width: 361px;
    height: 44px;
    font-size: 15px;
    font-weight: 600;
    color: @ppui-color-grey-700;
    background-color: @ppui-color-grey-300;
    border: 1px solid @ppui-color-grey-300;
    text-decoration: none;
    display: inline-block;
    line-height: 40px;

    &:focus,
    &:hover {
      background-color: darken(@ppui-color-grey-300, 10%);
    }
  }
}

.return_topp_btn {
  width: 100%;
  max-width: 350px;
  height: 38px;
  font-size: 18px;
  font-weight: 400;
  color: white;
  background-color: @ppui-color-blue-600;
  border: 1px solid @ppui-color-blue-600;
  border-radius: 25px;

  &:focus,
  &:hover {
    background-color: darken(@ppui-color-blue-600, 10%);
  }
}
