#donateWrapper {
  .error_page_landing {
    margin: 25px auto 50px;
  }

  .donate_btn_try {
    width: 100%;
    max-width: 300px;
  }

  .icon-attention-large {
    color: @ppui-color-orange-500;
  }

  .icon-critical-large {
    color: @ppui-color-red-500;
  }

  .error_message {
    margin: 50px auto 10px;
    font-size: 18px;
    line-height: 1.3;
    font-weight: 200;
  }

  .error_message1 {
    margin: 50px auto 10px;
    font-size: 26px;
    font-weight: 200;
  }

  .error_message2 {
    p {
      margin: 0;
    }
    margin: 10px auto 20px;
    font-size: 16px;
    font-weight: 200;
    width: 100%;
    max-width: 400px;
  }

  .err_update_amount {
    margin: 0;
    color: @ppui-color-red-500;
    display: none;
  }

  .service_error_holder {
    .error-text {
      .guest-errors {
        display: inline;
      }
    }
  }

  .sender-eligibility-error-tile,
  .receiver-eligibility-error-tile {
    text-align: center;
  }

  div.payment-error {
    border: none;
    padding: 211px 48px 0;
    @media @tabletAndMobile {
      padding: 62px 16px 0;
    }
  }
}

.receiver-eligibility-error-container {
  position: relative;

  .close-button {
    top: 6px;
    right: 8px;
  }

  @media (max-width: 450px) {
    margin-top: 0px;
    margin-bottom: 100px;
  }
}

.sender-eligibility-error-modal {
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding: 64px 16px;

  @media (max-width: 450px) {
    width: 100%;
    margin-top: 50px;
  }

  .sender-eligibility-error-tile {
    padding-top: 30vh;
  }
}

.crs-error-tile,
.generic-error-tile {
  text-align: center !important;

  @media @tabletAndMobile {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  img {
    margin-bottom: 24px;
  }

  .header {
    margin-bottom: 24px;
  }

  .message1 {
    margin-bottom: 12px;
  }

  .goBackBtn {
    margin-top: 24px;
  }

  .body-text {
    margin-top: 24px;
    text-align: left;
  }
}

.cc-error-message {
  margin: 12px 64px;
}

.generic-service-error {
  margin-top: 1.5rem;
  display: flex;
  span {
    vertical-align: middle;
    margin-right: 10px;
  }
}
