@import '../buttonFactory/buttonFactoryVariables.less';

.banner {
  position: relative;
  height: 200px;
  margin-bottom: 8px;
}

.banner-v2 {
  position: relative;
  height: 200px;
  margin-bottom: 24px;
  @media @mobile {
    height: 100%;
    max-height: 200px;
  }
}

.mobile-banner {
  position: relative;
  height: 24vh;
  margin-bottom: 0px;
  max-height: 122px;
}

.logo-banner {
  position: relative;
  height: 200px;
  margin-bottom: 70px;
  @media @mobile {
    height: 172px;
    margin-bottom: 8px;
  }
}

.banner-img {
  max-height: 200px;
  @media @mobile {
    height: 122px;
  }
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.avatar-rounded-container {
  position: absolute;
  left: 128px;
  @media @mobile {
    left: 64px;
  }
  transform: translate(-50%, -50%);
  border-radius: 50%;
  width: 96px;
  height: 96px;
  border: 0.0625rem solid #ffffff;
  background: #fff;
  overflow: hidden;
}

.avatar {
  width: 90%;
  height: 100%;
  object-fit: scale-down;
  display: block;
  margin: auto;
}

.avatar-logo {
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-pink-bg {
  background-color: #ffcfef;
  color: #9c2874;
}

.logo-blue-bg {
  background-color: #1072eb;
  color: #ffffff;
}

.logo-green-bg {
  background-color: #25694f;
  color: #cbf3ec;
}

.logo-purple-bg {
  background-color: #761ad3;
  color: #f2e6fe;
}

.logo-yellow-bg {
  background-color: #ffc43a;
  color: #785504;
}

.logo-magenta-bg {
  background-color: #9c2874;
  color: #ffcfef;
}

.logo-icon {
  font-size: 3.75rem !important;
}

.logo-initials {
  font-size: 2.5rem !important;
}

.gnc-subheader {
  margin-left: auto;
  margin-right: auto;

  .charity-header {
    margin-top: 0px;
    margin-bottom: 8px;
  }
  .charity-subtitle {
    display: flex;
    align-items: center;
    align-content: center;
    .subtitle-icon {
      color: @ppui-v2-color-blue-600;
      margin-right: 12px;
    }
  }
}

.gn-subheader {
  width: 90%;
  .gn-purpose {
    margin-top: 0px;
  }
  .personal-fundraiser-header {
    margin-bottom: 8px;
  }
}
