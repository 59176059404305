.donate_confirmation {
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  width: 100%;
  max-width: 557px;
  padding-top: 5px;
  padding-bottom: 15px;
  border: 1px solid @ppui-color-grey-400;
  border-radius: 5px;

  .confirmation_img_holder {
    text-align: center;

    img {
      width: 150px;
    }
  }

  .confirmation_header {
    margin: 0 10px auto;

    h4 {
      font-weight: 500;
      margin: 0;
      font-size: 20px;
      line-height: 1.4em;
    }
  }

  .confirmation_reciept_wrapper {
    margin: 20px auto;

    h6 {
      margin: 0;
      opacity: 0.6;
      font-weight: 200;
    }
  }

  .confirmation_return {
    margin: 30px auto 0;

    h4 {
      font-weight: 200;
      margin: 0;

      a {
        text-decoration: none;
        color: @ppui-color-blue-600;
      }
    }
  }

  .service_error_holder {
    display: none;

    p {
      color: @ppui-color-red-500;
    }
  }
}

#donateWrapper {
  .confirmation {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    position: relative;

    &.ppf {
      padding: 40px 20px;
    }

    .close-button {
      top: 8px;
      right: 12px;
    }

    .confirmation_img_holder {
      text-align: center;

      img {
        width: 150px;
      }

      @media @mobile {
        margin-left: 1rem;
        margin-right: 1rem;
      }
    }

    .confirmation_header {
      margin: 0 10px auto;
      word-wrap: break-word;

      h4 {
        font-weight: 500;
        margin: 0;
        font-size: 20px;
        line-height: 1.4em;
      }
    }

    .confirmation_return {
      margin: 30px auto 0;

      h4 {
        font-weight: 200;
        margin: 0;

        a {
          text-decoration: none;
          color: @ppui-color-blue-600;
        }
      }
    }
  }

  .confirmation_reciept_wrapper {
    padding-top: 12px;
    margin-bottom: 12px;
    color: @ppui-v2-color-black;

    h6 {
      margin: 0;
      // opacity: 0.6;
      font-size: 14px;
      font-weight: 200;
    }
  }

  .sign_up_alert {
    margin-bottom: 45px;

    @media only screen and (max-width: 576px) {
      margin-bottom: 0;
    }
  }

  .add_cc_failed_alert {
    margin: 24px 0;

    @media only screen and (max-width: 616px) {
      margin: 12px 0;
    }

    @media @mobile {
      margin: 0;
    }
  }
}

#copyLinkModal {
  width: 612px;
  padding-bottom: 30px;

  @media only screen and (max-width: 576px) {
    width: 100%;
  }
}

.copy-link-modal {
  max-width: 612px;
  text-align: center;

  #header_copyLinkModal {
    margin-top: 0;
  }
}

.social_share_header {
  margin-bottom: 14px;

  &.externalFlow {
    margin-bottom: 40px;
  }

  &.inContext {
    margin-bottom: 28px;
  }
}

.member_success_confirmation {
  margin: -40px auto 20px auto;

  & > img {
    height: 120px;
  }
}

.pending-content {
  margin: 0 auto !important;
}

.post-donation-share-widget {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin-top: 2rem;
  margin-bottom: 2rem;

  .post-donation-transaction-id {
    display: flex;
    align-items: center;

    @media @mobile {
      justify-content: center;
      margin-bottom: 2rem;
    }
  }

  .post-donation-social-share {
    align-self: flex-end;

    &.post-donation-social-share-in-context {
      > div {
        justify-content: center !important;
      }
    }
    > div {
      justify-content: end !important;

      @media @mobile {
        justify-content: center !important;
        margin-bottom: 2rem;
      }
    }

    .post-donation-share-text {
      display: flex;
      align-items: center;
    }

    nav:has(> .post-donation-share-menu) {
      right: -36%;
      top: 85%;
    }
  }
}

.post-donation-share-item-menu {
  p[class*='item_text-text_body'] {
    @media @mobile {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      flex-direction: row;
      width: 100%;
    }

    span:nth-of-type(2) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .sheetCopiedLabel {
      display: flex;
      align-items: center;
      justify-content: center;

      span:first-of-type {
        margin-right: 8px;
      }
    }
  }
}
