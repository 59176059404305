.tooltip {
  background-color: #f5fbfe;
  border: solid 1px #50c7f9;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.29);
  padding: 4px;
  max-width: 260px;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #2c2e2f;
  z-index: 10;
  opacity: 1;

  p {
    font-size: 12px;
    color: #737a7f;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
  }

  .tooltip-inner {
    text-align: left;
    font-size: 13px;
    line-height: 1.6;
  }
}

.tooltip[x-placement^='top'] {
  margin-bottom: 5px;
  .tooltip-arrow {
    border-width: 5px 5px 0 5px;
    border-color: #50c7f9 transparent transparent transparent;
    bottom: -5px;
    left: calc(10% - 5px);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.tooltip[x-placement^='bottom'] {
  margin-top: 5px;
  .tooltip-arrow {
    border-width: 0 5px 5px 5px;
    border-color: transparent transparent #50c7f9 transparent;
    top: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.tooltip[x-placement^='right'] {
  margin-left: 5px;
  .tooltip-arrow {
    border-width: 5px 5px 5px 0;
    border-color: transparent #50c7f9 transparent transparent;
    left: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
  }
}

.tooltip[x-placement^='left'] {
  margin-right: 5px;
  .tooltip-arrow {
    border-width: 5px 0 5px 5px;
    border-color: transparent transparent transparent #50c7f9;
    right: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
  }
}
